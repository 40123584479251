@import '~@mankibusiness/react-dropzone-uploader/dist/styles.css';
@import '~plyr/src/sass/plyr.scss';

@import '_vars';

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';
@import '_functions-override';
// Don't import ~bootstrap/scss/bootstrap, as it will undo our function overrides
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/images';
@import '~bootstrap/scss/code';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/button-group';
@import '~bootstrap/scss/input-group';
@import '~bootstrap/scss/custom-forms';
@import '~bootstrap/scss/nav';
@import '~bootstrap/scss/navbar';
@import '~bootstrap/scss/card';
@import '~bootstrap/scss/breadcrumb';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/badge';
@import '~bootstrap/scss/jumbotron';
@import '~bootstrap/scss/alert';
@import '~bootstrap/scss/progress';
@import '~bootstrap/scss/media';
@import '~bootstrap/scss/list-group';
@import '~bootstrap/scss/close';
@import '~bootstrap/scss/toasts';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/tooltip';
@import '~bootstrap/scss/popover';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/print';

body {
    font-family: 'Proxima Nova', 'Open Sans', sans-serif;
    color: #333;
    min-width: 992px;
}

.btn {
    &:not(.btn-sm) {
        border-radius: 24px;
        padding-left: 24px;
        padding-right: 24px;
        padding-top: .5rem;
        padding-bottom: .5rem;
        font-weight: 600;
        font-size: 0.95rem;
    }
    &.btn-sm {
        border-radius: 20px;
    }

    &.btn-light {
        background: #fff;
        color: theme-color('primary');
        border: 0;
    }
}

// next-page-transitions animations
.page-transition-enter {
    opacity: 0;
}
.page-transition-enter-active {
    opacity: 1;
    transition: opacity 300ms;
}
.page-transition-exit {
    opacity: 1;
}
.page-transition-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}

.ReactModal__Overlay {
    background-color: rgba(#000, .75);
}
